import enConfirmEmail from "./confirm-email/en";
import enDataCompletion from "./data-completion/en";
import enSetPassword from "./set-password/en";
import enUserhandling from "./user-handling/en";
import enUserprofile from "./user-profile/en";
import enProjectSideBar from "./project-side-bar/en";
import enAffiliateDashboard from "./affiliate-dashboard/en";
import enInvestWizzard from "./invest-wizzard/en";
import enInvestment from "./investment/en";
import enMyInvestments from "./my-investments/en";
import enProjectDocuments from "./project-documents/en";
import enProjectFundedList from "./project-funded-list/en";
import enProjectList from "./project-list/en";
import enProjectListItem from "./project-list-item/en";
import enProjectOwnerDashboard from "./project-owner-dashboard/en";
import enProjectUpdates from "./project-updates/en";
import enMyCompany from "./my-company/en";
import enWebsiteHomeInfo from "./website-home-info/en";
import enProjectApplication from "./project-application/en";

import { ITextsRest } from "./ITranslationCollection";

const enRest: ITextsRest = {
  save_button: "Save",
  saved: "Saved!",
  error_when_saving: "Error when saving",
  error_when_loading: "Error when loading",
  please_loggin_first: "Please log in to see the content of this page.",
  server_is_unavailable: "Unfortunatly, we could not reach our server. Please try again later.",
  place_auto_complete_alert_message: "place_auto_complete_alert_message",
  place_auto_complete_alert_description: "place_auto_complete_alert_description",
  cancel_button: "Cancel",
  update_button: "Update",
  delete_button: "Delete",
  complete_idenfication: "Please complete your identification"
};

const en = {
  ...enConfirmEmail,
  ...enDataCompletion,
  ...enSetPassword,
  ...enUserhandling,
  ...enUserprofile,
  ...enRest,
  ...enProjectSideBar,
  ...enAffiliateDashboard,
  ...enInvestWizzard,
  ...enInvestment,
  ...enMyInvestments,
  ...enProjectDocuments,
  ...enProjectFundedList,
  ...enProjectList,
  ...enProjectListItem,
  ...enProjectOwnerDashboard,
  ...enProjectUpdates,
  ...enMyCompany,
  ...enWebsiteHomeInfo,
  ...enProjectApplication
};

export default en;
