import deAffiliateDashboard from "./affiliate-dashboard/de";
import deConfirmEmail from "./confirm-email/de";
import deDataCompletion from "./data-completion/de";
import deInvestWizzard from "./invest-wizzard/de";
import deInvestment from "./investment/de";
import deMyInvestments from "./my-investments/de";
import deProjectDocuments from "./project-documents/de";
import deProjectFundedList from "./project-funded-list/de";
import deProjectList from "./project-list/de";
import deProjectListItem from "./project-list-item/de";
import deProjectSideBar from "./project-side-bar/de";
import deProjectOwnerDashboard from "./project-owner-dashboard/de";
import deProjectUpdates from "./project-updates/de";
import deSetPassword from "./set-password/de";
import deUserhandling from "./user-handling/de";
import deUserprofile from "./user-profile/de";
import deMyCompany from "./my-company/de";
import deWebsiteHomeInfo from "./website-home-info/de";
import ITranslationCollection, { ITextsRest } from "./ITranslationCollection";
import deProjectApplication from "./project-application/de";

const deRest: ITextsRest = {
  save_button: "Speichern",
  saved: "Gespeichert!",
  error_when_saving: "Fehler beim Speichern",
  error_when_loading: "Fehler beim Laden",
  please_loggin_first: "Bitte melden Sie sich zunächst an oder registrieren Sie sich, um auf diese Inhalte zugreifen zu können.",
  server_is_unavailable:
    "Wir konnten leider keine Verbindung zu unserem Server herstellen. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
  place_auto_complete_alert_message: "Hinweis",
  place_auto_complete_alert_description: `
    Sollten Sie Ihre Adresse nicht auswählen können, schreiben Sie uns bitte eine E-Mail an: <a href="mailto:mail@bettervest.com?subject=Adresse nicht gefunden">mail@bettervest.com</a>
  `,
  cancel_button: "Abbrechen",
  update_button: "Ändern",
  delete_button: "Löschen",
  complete_idenfication: "Bitte vervollständigen Sie Ihre Identifizierung"
};

const de: ITranslationCollection = {
  ...deAffiliateDashboard,
  ...deConfirmEmail,
  ...deDataCompletion,
  ...deInvestWizzard,
  ...deInvestment,
  ...deMyInvestments,
  ...deProjectDocuments,
  ...deProjectFundedList,
  ...deProjectList,
  ...deProjectListItem,
  ...deProjectSideBar,
  ...deProjectOwnerDashboard,
  ...deProjectUpdates,
  ...deSetPassword,
  ...deUserhandling,
  ...deUserprofile,
  ...deRest,
  ...deMyCompany,
  ...deWebsiteHomeInfo,
  ...deProjectApplication
};

export default de;
