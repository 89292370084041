// import ITexts from "./ITexts";

const en = {
  projectapplication_apply: "Send",
  projectapplication_contactPerson: "Contact Person",
  projectapplication_email: "Email (very important for communication!)",
  projectapplication_phone: "Phone Number",
  projectapplication_companyName: "Registered Company Name",
  projectapplication_numberOfYearsInBusiness: "Years in Business",
  projectapplication_country: "Country",
  projectapplication_companyAddress: "Company Address",
  projectapplication_requiredFinanceVolume: "Required Finance Volume",
  projectapplication_selfEquity: "Self Equity in %",
  projectapplication_financingType: "Financing Type",
  projectapplication_description: "Project Description",
  projectapplication_securities: "Securities",
  financingType_debt: "Debt",
  financingType_equity: "Equity",
  financingType_participationRights: "Participation Rights",
  projectapplication_successTitle: "Your application has been successfully received!",
  projectapplication_successSubTitle:
    "Please check your email for a link to upload the required documents and complete your application. We look forward to exploring a collaboration on your project!",
  projectapplication_ourProjects: "Our Projects",
  projectapplication_newApplication: "New Application"
};

export default en;
