import ITexts from "./ITexts";

const de: ITexts = {
  projectapplication_apply: "Send",
  projectapplication_contactPerson: "Kontakperson",
  projectapplication_email: "E-Mail-Adresse",
  projectapplication_phone: "Telefonnummer",
  projectapplication_companyName: "Name des Unternehmens",
  projectapplication_numberOfYearsInBusiness: "Wie viele Jahre ist Ihr Unternehmen bereits im Geschäft?",
  projectapplication_country: "Land",
  projectapplication_companyAddress: "Adresse des Unternehmens",
  projectapplication_requiredFinanceVolume: "Benötigtes Finanzierungsvolumen",
  projectapplication_selfEquity: "Wie viel Eigenkapital können Sie selbst investieren? %",
  projectapplication_financingType:
    "Bitte wählen Sie die benötigte Finanzierungsart für dieses Projekt aus. Bitte beachten Sie, dass wir derzeit nur bei Fremdkapitalinvestitionen zusammenarbeiten",
  projectapplication_description: "Beschreibung des Projekts",
  projectapplication_securities: "Welche Sicherheiten können Sie anbieten?",
  financingType_debt: "Fremdkapital",
  financingType_equity: "Eigenkapital",
  financingType_participationRights: "Partizipationsrechte",
  projectapplication_successTitle: "Ihre Bewerbung wurde erfolgreich eingereicht!",
  projectapplication_successSubTitle:
    "Bitte checken Sie Ihre E-Mails für den Link zum Hochladen der erforderlichen Dokumente und zum Abschluss Ihrer Bewerbung. Wir freuen uns darauf, eine Zusammenarbeit an Ihrem Projekt zu erkunden!",
  projectapplication_ourProjects: "Unsere Projekte",
  projectapplication_newApplication: "Neue Bewerbung"
};

export default de;
